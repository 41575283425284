/* Content Wrapper */
/* .quizzes-container {
  margin: 10px auto;
  max-width: 80%;
  align-self: center;
  padding: 10px;
  min-height: 100vh;
  background-color: var(--off-white-color);
  border-radius: 10px;
  box-shadow: 2px 4px 4px hsl(0deg 0% 0% / 0.44);
} */
/* .unordered-list {
  margin: 10px;
}
.unordered-list li {
  margin: 10px;
}
.unordered-list li a {
  text-decoration: none;
  color: black;
}
.unordered-list li:hover {
  text-decoration: underline;
  cursor: pointer;
} */
/* h1 {
  margin: 10px;
} */
/* Colors */
/* :root {
  --multiple-choice-color: rgb(0, 0, 0);
  --off-white-color: #fff9fb;
  --off-white-color-hover: #b9b8b8;
  --light-black-color: #3b3d40;
} */

/* .quiz-container {
  margin-inline: auto;
  min-height: 100vh;
  margin: 10px auto 10px auto;
  width: 80%;
  padding: 10px;
  font-size: medium;
  border-radius: 5px;
  box-shadow: 2px 4px 4px hsl(0deg 0% 0% / 0.44);
  background: rgb(92, 92, 92);
  background: radial-gradient(
    circle,
    rgba(92, 92, 92, 1) 0%,
    rgba(59, 61, 64, 1) 100%
  );
} */
.quiz-title {
  display: block;
  text-align: center;
  margin: 10px auto;
  /* color: var(--off-white-color); */
}

.question-container,
.score-container {
  width: min(100vw - 3rem, 80ch);
  margin-inline: auto;
  /* background-color: var(--off-white-color); */
  margin: 10px auto 10px auto;
  width: 95%;
  padding: 10px 0px 20px 20px;
  border-radius: 10px;
}
/* Grade */
.score-container {
  text-align: center;
  font-weight: bold;
}
.question-text {
  margin: 0px;
  padding-left: 0px;
  font-weight: bold;
  font-size: 1.2rem;
}
.multiple-choice-label {
  font-family: system-ui, sans-serif;
  font-size: 1.2rem;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  margin-top: 0.5em;
  background-color: #e6fbfe;
  border-radius: 10px;
}
/* Multiple Choice Inputs */
input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  margin: 0;
  font: inherit;
  color: #0fc9df;
  width: 0.95em;
  height: 0.95em;
  border: 0.085em solid currentColor;
  border-radius: 50%;
  transform: translateY(0.1em);
  display: grid;
  place-content: center;
}
input[type="radio"]::before {
  content: "";
  width: 0.55em;
  height: 0.55em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #0fc9df;
}
input[type="radio"]:checked::before {
  transform: scale(1);
}
/* .submit-button-quiz {
  padding: 10px;
  margin-inline: auto;
  margin: 10px auto 10px 25%;
  width: 50%;
  background-color: var(--off-white-color);
  color: black;
  border: none;
  font-size: 1.3rem;
  border-radius: 10px;
  cursor: pointer;
}
.submit-button-quiz:hover {
  background-color: #b9b8b8;
  font-size: 1.3rem;
  transform: scale(1.05);
} */
button {
  margin: 2px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  background-color: #4a58d3;
  color: #fff;
  cursor: pointer;
}
/* button:hover {
  background-color: #444d99;
} */
.doc-container {
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
  /* background-color: var(--off-white-color); */
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 2px 4px 4px hsl(0deg 0% 0% / 0.44);
}

.table-title {
  color: black;
  font-size: 20px;
  background-color: #ffeaa0;
  padding: 0.5rem;
  border-radius: 16px 16px 0px 0px;
}
.table-head {
  background-color: #fffcf0;
}

.btn-complete,
.btn-pending {
  color: #34a400;
  background-color: #d4ffc0;
  border-radius: 24px;
  padding: 6px 18px;
}
.btn-pending {
  background-color: #fff5c0;
  color: #c14332;
}
.btn-complete:hover,
.btn-pending:hover {
  background-color: auto;
  cursor: default;
}
.action {
  color: #0fc9df;
}
tbody tr:hover,
tbody td:hover {
  background-color: unset !important;
}
.pointer {
  cursor: pointer;
}
