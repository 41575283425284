/* *,
*:before,
*:after {
  box-sizing: border-box;
} */
/* Colors */
/* :root {
  --off-white-color: #fff9fb;
  --off-white-color-hover: #b9b8b8;
  --light-black-color: #3b3d40;
  --green-submit-color: #4caf50;
  --green-submit-color-hover: #45a049;
} */
/* Login Page Container */
/* .login-container {
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 161px;
  padding-left: 750px;
} */
/* Login Form */
/* .login-form {
  width: 500px;
  margin: 0 auto;
  margin-top: 50px;
  background-color: var(--off-white-color);
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 2px 4px 4px hsl(0deg 0% 0% / 0.44);
  position: absolute;
  top: 70px;
  right: 330px;
} */
/* .float-child h3 {
  text-align: center;
}
.login-form p {
  text-align: center;
} */
.login
/* Error Message */
#error-message {
  display: none;
  padding: 5px;
  margin: auto;
  color: red;
  font-weight: 700;
  text-align: center;
}
/* Input Labels */
/* .login-label {
  display: block;
  margin-bottom: 10px;
} */
/* Inputs */
/* .login-input[type="password"],
.login-input[type="email"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
} */
/* Sign In Button */
.login-btn {
  width: 100%;
  background: linear-gradient(90deg, #1baccf 0%, #512982 100%);
  border-radius: 8px;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  /* border-radius: 4px; */
  cursor: pointer;
  font-size: larger;
}

.img {
  background-image: url(../../Assets/Login\ Page.png);
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}
