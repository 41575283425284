/*New UI Design*/
.reg-form-div {
  width: 100%;
  margin-top: 50px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 2px 4px 4px hsl(0deg 0% 0% / 0.44);
}

@media only screen and (max-width: 768px) {
  .reg-form-div {
    border: none;
    box-shadow: none;
    padding: 0;
  }
}
/* select:invalid {
  color: #737b7d !important;
  padding-left: 0px !important;
} */
