/*New UI Design*/
.btn2 {
  background: none;
  float: right;
  padding: 0;
  margin-right: 10px;
}

.table-title {
  color: black;
  font-size: 20px;
  background-color: #ffeaa0;
  padding: 0.5rem;
  border-radius: 16px 16px 0px 0px;
  justify-content: space-between;
}

.custom-height {
  height: 180px;
}

@media (max-width: 576px) {
  .custom-height {
    height: 135px;
  }
}
