/*New UI Design*/
.thumbnail {
  flex-wrap: wrap;
  height: 270px;
  width: 300px;
  margin-top: 15px;
  margin-left: 5px;
  cursor: pointer;
  border-radius: 10px;
}

.thumbnail:hover {
  transform: scale(1.02);
}
/* thumbnail image */
.thumbnail > img {
  position: relative;
  width: 280px;
  padding: 0px;
  border-radius: 10px;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.44);
}
/* thumbnail description */
.thumbnail p {
  width: 260px;
  position: absolute;
  top: 20px;
  padding: 10px;
  font-weight: 500;
  font-size: 18px;
  color: white;
}

/* Style the YouTube player */
/* .player {
  width: 100%;
  height: 50vh;
  min-width: 500px;
  min-height: 300px;
  max-height: 478px;
  max-width: 854px;
  border-radius: 10px;
} */

/* Style the container for the YouTube player */
/* .player-container {
  min-width: 40vw;
  max-width: 913px;
  width: 100%;
  padding: 2%;
  position: absolute;
  top: 60vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 1;
  flex-direction: column;
  align-items: center;
  background-color: var(--light-black-color);
  box-shadow: 2px 4px 4px hsl(0deg 0% 0% / 0.44);
  border-radius: 10px;
}

.player-title {
  justify-content: center;
  text-align: center;
  background-color: var(--off-white-color);
  padding: 5px;
  margin: 25px 40px 25px 50px;
  border-radius: 10px;
  box-shadow: 2px 4px 4px hsl(0deg 0% 0% / 0.44);
}

.player-close-btn {
  cursor: pointer;
  background-color: var(--off-white-color);
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 16px;
  border-radius: 10px;
  box-shadow: 2px 4px 4px hsl(0deg 0% 0% / 0.44);
  font-size: large;
}

.player-close-btn:hover {
  box-shadow: 2px 4px 4px hsl(0deg 0% 0% / 0.44);
  transform: scale(1.05);
  background-color: var(--off-white-color-hover);
} */

.pdf-btn {
  background-color: #13c9de;
  color: #ffffff;
  padding: 5px 14px;
  border: 1px solid #006874;
  border-radius: 8px;
  font-weight: 300;
  min-width: 100px;
  max-height: 50px;
}
.list-box {
  padding: 10px 20px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
@media only screen and (max-width: 768px) {
  .list-box {
    padding: 10px 5px;
  }
}

.custom-dot-list-style {
  width: 23px;
  height: 7px;
  background: #0fc9df;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  display: block;
  cursor: pointer;
  /* transition: width 2s; */
}
.active {
  width: 60px;
  height: 7px;
  background: #0fc9df;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  display: block;
  /* transition: 0.5s; */
}

.frame {
  width: 100%;
  height: 80vh;
}
