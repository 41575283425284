/* New UI Design */
.logo {
  color: black;
  height: 20;
  height: 40px;
  width: 154px;
  /* background-color: rgb(91, 230, 251); */
  background: #ebfbfd;
  margin-left: 20px;
}

nav {
  position: fixed;
  /* top: 0;
  left: 0; */
  height: 100vh;
  background: #ebfbfd;
  width: inherit;
  /* overflow-x: hidden; */
  padding-top: 20px;
  background-image: url("../../Assets/Side\ nav\ image.png");
  background-repeat: no-repeat;
  background-size: cover;
}

nav ul {
  list-style-type: none;
  margin: 30px auto;
  padding-left: 20px;
  /* padding: 0;
  position: absolute;
  top: 84px;
  left: 15px; */
  font-size: 15px;
  font-family: sans-serif;
}

nav li {
  margin-bottom: 10px;
}

nav a {
  display: block;
  color: black;
  padding: 10px 0px 10px 10px;
  text-decoration: none;
}

nav li:hover {
  background-color: rgb(91, 230, 251);
  text-decoration: none;
  color: white;
  border-radius: 10px;
  margin-right: 10px;
}

nav li:hover svg path {
  fill: white;
  stroke: white;
}

nav a:hover {
  color: white;
}
