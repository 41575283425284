* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
:root {
  --body-background-color: #d3d4d9;
}
body {
  /* display: flex;
  flex-direction: column; */
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
  font-size: clamp (1rem, 4vw, 3rem);
  font-family: system-ui, sans-serif;
  font-weight: inherit;
  /* background-color: var(--body-background-color); */
}
@media screen and (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

.text-cyan {
  color: #0fc9df;
}

.text-pink {
  color: #dd5471;
}

.row {
  --bs-gutter-x: 0 !important;
}

.input-class[type="text"],
.input-class[type="date"],
.input-class[type="datetime-local"],
.input-class[type="dropdown"],
.input-class[type="email"] {
  width: 100%;
  border: 0;
  border-bottom: 2px solid #737b7d;
  outline: 0;
  margin: 10px 0px 5px;
  background: transparent;
}
