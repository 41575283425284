/* New UI Design */

header {
  /* position: absolute; */
  /* width: 100vw; */
  height: 72px;
  left: 0px;
  top: 0px;
  font-family: sans-serif;
  font-size: 15px;
  background: #0fc9df;
}

header .drop-btn {
  /* position: absolute; */
  width: 20px;
  height: 36px;
  /* left: 1122px;
  top: 7px; */
  background-color: #0fc9df;
  /* color: black; */
}

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.drop-btn {
  /* background: #04aa6d; */
  border: none;
  font-size: 17px;
  padding: 0px;
  color: #fff;
}
.drop-btn:hover {
  background: none;
}

.dropdown-content {
  display: none;
  background: #f1f1f1;
  min-width: 160px;
  position: absolute;
  right: 1px;
  top: 36px;
  z-index: 1;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
  display: block;
  color: #000;
  text-decoration: none;
  padding: 17px;
  font-size: 18px;
}

.dropdown-content a:hover {
  background: #ddd;
  color: #000;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* mobile navbar */

.offcanvas.offcanvas-start {
  background-image: url("../../Assets/Side\ nav\ image.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.mobile-btn {
  background-color: #0fc9df;
}
.mobile-nav ul {
  list-style-type: none;
  margin: 30px auto;
  padding-left: 20px;
  /* padding: 0;
  position: absolute;
  top: 84px;
  left: 15px; */
  font-size: 15px;
  font-family: sans-serif;
}

.mobile-nav li {
  margin-bottom: 10px;
}

.mobile-nav a {
  display: block;
  color: black;
  padding: 10px 0px 10px 10px;
  text-decoration: none;
}
