/*New UI Design*/
.rep-form-div {
  width: 100%;
  margin-top: 50px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 2px 4px 4px hsl(0deg 0% 0% / 0.44);
}

select:invalid {
  color: #737b7d !important;
  padding-left: 0px !important;
}

option {
  color: black;
}

.rep-date::before {
  color: #737b7d;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}

.rep-date:invalid {
  color: #737b7d;
}

.rep-date:focus::before,
.rep-date:valid::before {
  content: "" !important;
}

/* Style for datetime-local input */
.rep-datetime-local::before {
  color: #737b7d;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}

.rep-datetime-local:invalid {
  color: #737b7d;
}

.rep-datetime-local:focus::before,
.rep-datetime-local:valid::before {
  content: "" !important;
}

@media only screen and (max-width: 768px) {
  .rep-form-div {
    border: none;
    box-shadow: none;
    padding: 0;
  }
}
