/*New UI Design*/
.top-section-reg {
  background-image: url("../../Assets/Rectangle\ 54.png");
  border: 1px solid;
  padding: 10px;
  border-radius: 10px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
}

/* Home Title */
#home-h1 {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

/* .top-section-reg .date {
  height: 30px;
  color: #f1a81a;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
} */
#register-btn {
  text-decoration: none;
  color: white;
}
.registration-indicator {
  border-radius: 10px;
  /* position: absolute; */
  top: 2px;
  right: 2px;
  background-color: green;
  color: rgb(0, 0, 0);
  padding: 5px 15px;
}

.registration-indicator span {
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  color: rgb(0, 0, 0);
}

/* .home-icon {
  height: 100px;
  padding: 0px;
  padding-right: 17px;
  padding-left: 10px;
} */

.carousal-box {
  background-color: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 3px;
}

.reg-box,
.com-box {
  background-color: #60d106;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: 47%;
  margin: 3px;
}
.com-box {
  background-color: #10a5c6;
}

.display-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.react-multiple-carousel__arrow {
  background-color: #0fc9df !important;
  min-width: 35px !important;
}
